<template>
    <div class="drag-component">
        <component :is="layout" :lightMode="lightMode" :data="data" :config="config" :name="name"></component>
    </div>
</template>

<script>
import first from 'lodash/first'

export default {
    props: {
        data: {
            type: Object,
            default: () => {
            }
        },
        config: {
            type: Object,
            default: () => {
            }
        },
        name: {
            type: String,
            default: ''
        },
        lightMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        layout() {
            let name = first(this.config?.conf)

            return () => import( `@/components/PageComponents/DragSlider/${name}` )
        }
    }
}
</script>

